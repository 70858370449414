import React from 'react'
import './Testimonials.css'
import {AppWrap} from '../../wrapper'

const Testimonials=()=>{
  return (
    <div>Testimonials</div>
  )
}

export default AppWrap(Testimonials,'testimonials')